import React from 'react';
import { useSelector } from 'react-redux';
import TranslatedText from 'components/i18n/TranslatedText';
import { CopyButton } from 'components/admin2/ui/Button';
import useClipboardWithMessage from 'hooks/use-clipboard-with-message';
import {
  CopyButtonV3Wrapper,
  FullURLV3,
  FullURLV3Container,
  FullURLV3Wrapper,
  StyledTranslateContainer,
} from './styles';
import { getParentAndChildSlug, getSiteSlug } from 'services/app';

interface IFullURLPreviewProps {
  slug: string;
}

export default function({
  slug,
}: IFullURLPreviewProps) {
  const siteSlug = useSelector(getSiteSlug);
  const { parentSlug, childSlug } = useSelector(getParentAndChildSlug);

  const fullUrl = React.useMemo(() => {
    const host = window.location.host;
    if (childSlug) return `${host}/${siteSlug}${parentSlug}/${slug}`;
    return `${host}/${siteSlug}/${slug}`;
  }, [window.location.host, siteSlug, slug, parentSlug, childSlug]);

  const [copy, tooltipKey] = useClipboardWithMessage(fullUrl, { useStringKeys: true });

  return (
    <FullURLV3Wrapper>
      <StyledTranslateContainer>
        <TranslatedText stringKey="ADMIN_LABEL_FULL_URL_WITHOUT_COLON_TAG" />
      </StyledTranslateContainer>
      <FullURLV3Container>
        <FullURLV3>{fullUrl}</FullURLV3>
        <CopyButtonV3Wrapper>
          <CopyButton
            data-testid="copyFullURLButton"
            onClick={copy}
            tooltipKey={tooltipKey}
            tooltipPosition="bottom"
          />
        </CopyButtonV3Wrapper>
      </FullURLV3Container>
    </FullURLV3Wrapper>
  );
}
