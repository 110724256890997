import {
  ACCENT_PRIMARY,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  min-width: 167px;
  height: 32px;
  padding: 14px 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  background: ${ACCENT_PRIMARY};
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ADMIN_TEXT_BODY_S_BOLD}

  ${props => props.disabled && css`
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const EntitlementPurchaseText = styled.span`
  line-height: 15px;
  margin-right: 34px;
`;

export const EntitlementPrice = styled.strong`
  line-height: 14px;
`;
