import Icon from 'components/ui/Icon';
import styled, { IStyledProps, css } from 'styled-components';
import { StyleProps, activeColor, textColorContent } from './styles';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_HIGHLIGHT_SECONDARY } from 'style/constants';

export const DownArrow = styled(Icon).attrs({
  name: 'down-open',
}) <StyleProps>`
  color: ${textColorContent};
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => isOpen ? '180deg' : '0deg'});
  position: absolute;
  right: 7px;
  font-size: 17.5px;
  line-height: 0;
  cursor: pointer;
`;

export const CheckIcon = styled(Icon).attrs((props) => ({
  color: activeColor(props),
  name: 'checkmark',
})) <StyleProps>`
  margin-right: 10px;
  & svg {
    display: block;
    width: 11px;
    height: 11px;
  }
`;

export const ThreeDotsIcon = styled(Icon).attrs(() => ({
  color: ADMIN_HIGHLIGHT_SECONDARY,
  name: 'threeDots',
}))`
  width: fit-content;
  & svg {
    display: block;
    width: 14px;
    height: 14px;

    margin-right: 0;
  }
  z-index: 3;
`;

export const SiteNavigationCheckIcon = styled(Icon).attrs((props) => ({
  color: activeColor(props),
  name: 'checkmark',
})) <StyleProps>`
  margin-right: 16px;
  opacity: 0;
  & svg {
    display: block;
    width: 11px;
    height: 11px;
  }

  ${({ isActive }) => isActive && css`
    opacity: 1;
  `};
`;

export const PlusIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'plus',
})) <StyleProps>`
  width: fit-content;
  & svg {
    display: block;
    width: 20px;
    height: 20px;

    transform: translate(-4px, 9px) scale(0.8);
    margin-right: 6px;
  }
`;

export const PlusTinyIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'plusTiny',
})) <StyleProps>`
  width: fit-content;
  & svg {
    display: block;
    width: 16px;
    height: 16px;

    margin-right: 3px;
  }
`;

export const FolderIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'folderOutline',
})) <StyleProps>`
  width: fit-content;
  & svg {
    display: block;
    width: 18px;
    height: 18px;

    margin-left: 6px;
    margin-right: 2px;
    transform: translateY(-1px);
  }
`;

export const LinkIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'adminbarLink',
})) <StyleProps>`
  width: fit-content;
  & svg {
    display: block;
    width: 18px;
    height: 18px;

    margin-left: 6px;
    margin-right: 2px;
    transform: translateY(-1px);
  }
`;
