import { useReducer, useCallback } from 'react';
import { uploadMedia } from 'components/admin2/UploadZone';
import { ADMIN_SERVICE_BASE_URL, MEDIA_ASSETS_BASE_URL } from 'config';
import produce from 'immer';
import maestroApiRequest from 'services/maestro-api-client';
import { ASC_KEY, DESC_KEY, SORT_MAP } from './constants';

const initialState = {
  editItemModalOpen: false,
  errorMessageStringKey: null,
  images: [],
  loading: false,
  params: {
    limit: 100,
    search: '',
    skip: 0,
    sortBy: 'created',
    sortDirection: SORT_MAP[DESC_KEY],
    total: 0,
    type: { $regex: '^image' },
  },
  selectedItem: null,
  totalImageCount: 0,
};

const ACTION_REQUEST = 'ACTION_REQUEST';
const ACTION_RESPONSE = 'ACTION_RESPONSE';
const ACTION_NEXT_PAGE = 'ACTION_NEXT_PAGE';
const ACTION_SORT = 'ACTTION_SORT';
const ACTION_SEARCH = 'ACTION_SEARCH';
const ACTION_IMAGE_ADD_RESPONSE = 'ACTION_IMAGE_ADD_RESPONSE';
const ACTION_IMAGE_ADD_REQUEST = 'ACTION_IMAGE_ADD_REQUEST';
const ACTION_IMAGE_ADD_ERROR = 'ACTION_IMAGE_ADD_ERROR';
const RESET_STATE = 'RESET_STATE';
const reducer = (state, action) => {
  const { payload = {}, type } = action;
  switch (type) {
    case ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_RESPONSE:
      return produce(state, (draft) => {
        const { count, results, clear } = payload;
        draft.loading = false;
        draft.totalImageCount = count;
        draft.images = clear ? results : [...draft.images, ...results];
      });
    case ACTION_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.params.skip = payload;
      });
    case ACTION_IMAGE_ADD_RESPONSE: {
      return produce(state, (draft) => {
        draft.images[0] = payload;
      });
    }
    case ACTION_IMAGE_ADD_REQUEST:
      return produce(state, (draft) => {
        draft.images = [{
          _id: 'TEMP',
          temporaryImageUrl: payload,
        }, ...state.images];
        draft.errorMessageStringKey = null;
      });
    case ACTION_IMAGE_ADD_ERROR:
      return produce(state, (draft) => {
        draft.errorMessageStringKey = 'ADMIN_IMAGE_LIBRARY_ERROR_UPLOAD';
        draft.images.shift();
        draft.loading = false;
      });
    case ACTION_SEARCH:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.images = [];
        draft.params.skip = 0;
        draft.params.search = payload;
      });
    case ACTION_SORT:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.images = [];
        draft.params.skip = 0;
        draft.params.sortDirection = payload;
      });
    case RESET_STATE:
      // eslint-disable-next-line no-unused-vars
      return produce(state, (draft) => {
        // eslint-disable-next-line no-param-reassign, no-unused-vars
        draft = { ...initialState };
      });
    default:
      return state;
  }
};

export default (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    getNextPage: () => {
      const { images } = state;
      dispatch({
        payload: images.length,
        type: ACTION_NEXT_PAGE,
      });
    },
    handleAdd: async (files) => {
      const { primaryToken } = props;
      try {
        const temporaryImage = URL.createObjectURL(files[0]);
        dispatch({ payload: temporaryImage, type: ACTION_IMAGE_ADD_REQUEST });
        const response = await uploadMedia(files, primaryToken);
        if (!response.data) {
          throw new Error('Internal server error');
        }
        dispatch({ payload: response.data, type: ACTION_IMAGE_ADD_RESPONSE });
      } catch {
        dispatch({ type: ACTION_IMAGE_ADD_ERROR });
      }
    },
    // NOTE: need use callback or AjaxFetch will run and run bc it will
    // register the request/response callbacks as new funcs for each render
    handleAjaxRequest: useCallback(() => {
      dispatch({ type: ACTION_REQUEST });
    }, [state.params]),
    handleAjaxResponse: useCallback((response) => {
      dispatch({
        payload: response,
        type: ACTION_RESPONSE,
      });
    }, [state.params]),
    handleDelete: (imgId) => {
      const { primaryToken, siteId } = props;
      const { images } = state;

      maestroApiRequest({ primaryToken, siteId })
        .delete(`${ADMIN_SERVICE_BASE_URL}/media/${imgId}`)
        .then(() => {
          dispatch({
            payload: {
              clear: true,
              count: images.length - 1,
              results: images.filter(({ _id }) => _id !== imgId),
            },
            type: ACTION_RESPONSE,
          })
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(`Error deleting image ${imgId}: ${err}`);
        })
    },
    handleOpen: () => {
      // this.setState({ modalOpen: true });
    },
    handleSearch: (searchText) => {
      dispatch({
        payload: searchText,
        type: ACTION_SEARCH,
      });
    },
    handleSelectImage: (image) => {
      const { onClose, onSelect, onSelectAsUrl, siteId } = props;
      if (onSelectAsUrl) {
        const { ext, _id: mediaId } = image;
        onSelectAsUrl(`${MEDIA_ASSETS_BASE_URL}/${siteId}/${mediaId}.${ext}`);
      }
      onSelect?.(image);
      onClose();
      dispatch({
        type: RESET_STATE,
      });
    },
    handleSortToggle: () => {
      const { params: { sortDirection } } = state;
      const sortValue = sortDirection === SORT_MAP[ASC_KEY] ?
        SORT_MAP[DESC_KEY] : SORT_MAP[ASC_KEY];
      dispatch({
        payload: sortValue,
        type: ACTION_SORT,
      });
    },
  };
  return [state, actions];
};
