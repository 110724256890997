import React from 'react';
import AdminConfirmationModal from 'components/admin2/AdminConfirmationModal';
import { connect } from 'react-redux';
import {
  ConnectedProps,
  mapStateToProps,
  mapDispatchToProps,
} from './connected';

function ConfirmationModal({
  clearConfirmationAction,
  confirmationAction,
  hasUnsavedChanges,
  runConfirmationAction,
}: ConnectedProps) {
  return (hasUnsavedChanges && confirmationAction) ? (
    <AdminConfirmationModal
      cancelKey="ACTION_DENY"
      continueKey="ACTION_CONFIRM"
      hideIcon={true}
      maxWidth="310px"
      onBack={clearConfirmationAction}
      onConfirm={runConfirmationAction}
      textKey="ADMIN_UNSAVED_CHANGES_WARNING"
    />
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
