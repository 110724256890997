import React, { memo, ChangeEvent, ReactNode, ComponentType, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/admin2/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import Label from 'components/admin2/ui/Label';
import { ADMIN_FIELD_TEXT_INPUT, ADMIN_QUERY_TEXT_INPUT, INPUT_LENGTH } from 'style/mixins';
import { ADMIN_INPUT_BORDER_COLOR, SPACING_X_SMALL, ADMIN_ALERT_ERROR, ADMIN_SURFACE_5, ADMIN_TEXT_200 } from 'style/constants';
import ErrorMessage from 'components/admin2/ui/ErrorMessage';
import { TYPES } from './constants';
import { InputType } from 'zlib';
import withTooltip from 'components/core/withTooltip';
import type { TranslationKey } from 'hooks/use-translation';

export { TYPES };

export const CharsLengthHelper = styled.span`${INPUT_LENGTH};`;

export interface ITextInputProps {
  autoFocus?: boolean;
  children?: string | React.ReactNode;
  className?: string;
  'data-testid'?: string;
  description?: string | React.ReactNode;
  descriptionKey?: TranslationKey;
  disabled?: boolean;
  errorKey?: TranslationKey;
  hasButton?: boolean;
  hasError?: boolean;
  inputBorder?: string;
  inputId?: string;
  inputTestId?: string;
  inputTooltip?: string;
  inputType?: InputType;
  isRequired?: boolean;
  labelHintKey?: TranslationKey;
  labelIcon?: string;
  labelIconSize?: number;
  labelKey?: TranslationKey;
  labelPadding?: string;
  labelTextSize?: string;
  labelWhiteIcon?: boolean;
  maxlength?: number;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  padding?: string;
  placeholderKey?: TranslationKey;
  placeholderText?: string;
  prefixInputIcon?: string;
  readOnly?: boolean;
  setInputRef?: React.RefObject<HTMLInputElement>;
  showDangerErrorMessage?: boolean;
  stretch?: boolean;
  type?: string;
  value?: string | number;
}

function TextInput ({
  inputId,
  children,
  autoFocus,
  className = '',
  'data-testid': testId,
  description,
  descriptionKey,
  disabled = false,
  errorKey ='ADMIN_LABEL_REQUIRED',
  hasButton = false,
  hasError,
  inputBorder = '1px solid transparent',
  inputTestId,
  inputType = TYPES.TEXT,
  inputTooltip,
  isRequired = false,
  labelHintKey,
  labelIcon,
  labelIconSize,
  labelKey,
  labelPadding,
  labelTextSize,
  labelWhiteIcon = false,
  maxlength,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  padding,
  placeholderKey,
  placeholderText,
  prefixInputIcon,
  readOnly = false,
  showDangerErrorMessage = false,
  stretch = false,
  type = 'text',
  setInputRef,
  value,
}: ITextInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event.target.value);
  };

  const renderWithButton = useCallback((text?: string) => {
    return (
      <Wrapper>
        <Input
          data-testid={inputTestId}
          disabled={disabled}
          inputType={inputType}
          maxLength={maxlength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          autoFocus={autoFocus}
          placeholder={text}
          prefixInputIcon={prefixInputIcon}
          readOnly={readOnly}
          showDangerErrorMessage={showDangerErrorMessage}
          type={type}
          tooltip={inputTooltip}
          value={value}
          onKeyDown={onKeyDown}
        />
        <ButtonWrapper>
          {children}
        </ButtonWrapper>
      </Wrapper>
    );
  }, [
    hasButton, children, inputTestId, disabled, inputType,
    handleBlur, handleChange, onFocus, prefixInputIcon, readOnly,
    showDangerErrorMessage, type, inputTooltip, value, onKeyDown,
  ]);

  const renderWithPlaceholder = (text?: string) => {

    return (
      <Container className={className} data-testid={testId} padding={padding}>
        {(description || descriptionKey || labelKey || labelIcon) && (
          <Label
            description={description}
            descriptionKey={descriptionKey}
            errorKey={errorKey}
            hasError={hasError !== undefined ? hasError : isRequired && !value}
            icon={labelIcon}
            iconSize={labelIconSize}
            labelHintKey={labelHintKey}
            labelKey={labelKey}
            padding={labelPadding}
            textSize={labelTextSize}
            whiteIcon={labelWhiteIcon}
          />
        )}
        <FormContainer inputBorder={inputBorder} inputType={inputType} stretch={stretch}>
          {inputType === TYPES.SEARCH && <SearchIcon name="search" />}
          {prefixInputIcon && (
            <PrefixInput>
              <PrefixIcon name={prefixInputIcon} />
            </PrefixInput>
          )}
          {hasButton ? renderWithButton(text) : (
            <Input
              id={inputId}
              ref={setInputRef}
              data-testid={inputTestId}
              disabled={disabled}
              inputType={inputType}
              maxLength={maxlength}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={onFocus}
              placeholder={text}
              prefixInputIcon={prefixInputIcon}
              readOnly={readOnly}
              showDangerErrorMessage={showDangerErrorMessage}
              type={type}
              tooltip={inputTooltip}
              value={value}
              onKeyDown={onKeyDown}
            />
          )}
          {maxlength && (
            <CharsLengthHelper>
              {typeof value === 'string' ? value.length : 0}/{maxlength}
            </CharsLengthHelper>
          )}
        </FormContainer>
        {showDangerErrorMessage && <ErrorMessage errorMessageKey={errorKey} />}
      </Container>
    );
  };

  if (placeholderText || !placeholderKey) {
    return renderWithPlaceholder(placeholderText || undefined);
  }
  return (
    <TranslatedText
      stringKey={placeholderKey}
    >
      {(text) => renderWithPlaceholder(text)}
    </TranslatedText>
  );
}

export default memo(TextInput);

const Container = styled.div<{padding?: string}>`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  padding: ${({ padding }) => padding || '10px 0'};
  /* Hides number input arrows */
  & input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PrefixInput = styled.div`
  width: 42px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ADMIN_SURFACE_5};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const FormContainer = styled.div<{inputBorder: string; inputType: InputType; stretch: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  border-bottom: ${(props) => props.inputType === TYPES.SEARCH ? `1px solid ${ADMIN_INPUT_BORDER_COLOR(props)}` : 'none'};
  position: relative;
  ${(stretch) => stretch ? `align-self: stretch`: ''};
  &:after {
    content: "";
    pointer-events: none;
    border: ${({ inputBorder }) => inputBorder};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

const SearchIcon = styled(Icon)`
  margin-right: ${SPACING_X_SMALL};
`;

const BasicInput = styled.input<{inputType: InputType; prefixInputIcon?: string; showDangerErrorMessage: boolean}>`
  ${({ inputType }) => (inputType === TYPES.SEARCH ? ADMIN_QUERY_TEXT_INPUT : ADMIN_FIELD_TEXT_INPUT)} min-width: 0;
  ${({ showDangerErrorMessage }) => showDangerErrorMessage && css`border: 1px solid ${ADMIN_ALERT_ERROR};`};
  ${({ prefixInputIcon }) => (prefixInputIcon && css`border-top-left-radius: 0; border-bottom-left-radius: 0;`)};
`;

const Input = withTooltip(BasicInput, { containerCss: 'width: 100%;' });

const PrefixIcon = styled(Icon)`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: default;

  & svg {
    width: 18px;
    height: 18px;
  }

  & path {
    fill: ${ADMIN_TEXT_200};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0px;
  align-items: center;
  position: relative;
`;

const ButtonWrapper = styled.div`
  height: 100%;
`;
