import {
  MEDIA_ASSETS_BASE_URL,
  MEDIA_SERVICE_BASE_URL,
} from 'config';
import axios, { AxiosRequestConfig } from 'axios';
import { MouseEvent, useReducer } from 'react';
import { DimensionRequirements, ImageInfo, UploadZoneProps } from './types';
import { FileWithPreview } from 'react-dropzone';
import { injectT } from 'hooks/use-translation';

const ACTION_TYPES = {
  ERROR: 'error',
  OPEN_LIBRARY: 'OPEN_LIBRARY',
  TOGGLE_HOVER: 'TOGGLE_HOVER',
  TOGGLE_LIBRARY: 'TOGGLE_LIBRARY',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
  UPLOAD_REJECTED: 'UPLOAD_REJECTED',
  UPLOAD_REQUESTED: 'UPLOAD_REQUESTED',
  UPLOAD_SUCCESSFUL: 'UPLOAD_SUCCESSFUL',
} as const;

type TActions = typeof ACTION_TYPES;

const initialState = {
  errorKey: null as null | string,
  errorMsg: null as null | string,
  isLoading: false,
  isRejected: false,
  libraryActive: false,
};

const reducer = (
  state: typeof initialState,
  action: {
    payload?: any;
    status?: any;
    type: TActions[keyof TActions];
  },
) => {
  const { payload, type } = action;
  const { libraryActive } = state;
  switch (type) {
    case ACTION_TYPES.TOGGLE_LIBRARY:
      return {
        ...state,
        libraryActive: !libraryActive,
      };
    case ACTION_TYPES.UPLOAD_REQUESTED:
      return {
        ...state,
        errorKey: null,
        errorMsg: null,
        isLoading: true,
        isRejected: false,
      };
    case ACTION_TYPES.UPLOAD_FAILED:
      return {
        ...state,
        errorKey: 'UPLOAD_ZONE_ERROR_DEFAULT',
        isLoading: false,
        isRejected: true,
      };
    case ACTION_TYPES.UPLOAD_REJECTED:
      return {
        ...state,
        errorKey: payload.errorKey,
        errorMsg: payload.errorMsg,
        isLoading: false,
        isRejected: true,
      };
    case ACTION_TYPES.UPLOAD_SUCCESSFUL:
      return {
        ...state,
        errorKey: null,
        errorMsg: null,
        isLoading: false,
        isRejected: false,
      };
    case ACTION_TYPES.TOGGLE_HOVER:
      return {
        ...state,
        isUserHovering: action.status,
      };
    default:
      return state;
  }
};

class InvalidDimensionsError extends Error {
  errorKey?: string;
  errorMsg?: string;

  constructor({ errorKey, errorMsg }: { errorKey?: string; errorMsg?: string }) {
    super(errorKey || errorMsg);
    this.errorKey = errorKey;
    this.errorMsg = errorMsg;
  }
}

/**
 * Given a file, will load file into image object
 * and test against dimension constraints
 * @param url {string}
 * @param dimensionRequirements {DimensionRequirements}
 */
export const areDimensionsValid = injectT(
  (t) => async (url: string, dimensionRequirements?: DimensionRequirements) => {
    return new Promise<void>((resolve, reject) => {
      if (!(dimensionRequirements && dimensionRequirements.verify)) {
        resolve();
        return;
      }
      const img = new Image();
      const {
        exactDimensions,
        exactWidth,
        exactHeight,
        minWidth,
        minHeight,
        pixelWidth,
        pixelHeight,
      } = dimensionRequirements;
      const anyWidth = !pixelWidth;
      const anyHeight = !pixelHeight;
      img.onload = () => {
        if (exactDimensions) {
          if (img.width === pixelWidth && img.height === pixelHeight) {
            resolve();
          } else {
            reject(new InvalidDimensionsError({
              errorKey: 'UPLOAD_ZONE_ERROR_EXACT_SIZE',
            }));
          }
        } else {
          let widthOk = anyWidth || img.width <= pixelWidth;
          let heightOk = anyHeight || img.height <= pixelHeight;
          const errorKey = 'UPLOAD_ZONE_ERROR_SIZE_LIMIT';
          let errorMsg = '';
          if (exactWidth) {
            widthOk = img.width === pixelWidth;
            if (!widthOk) {
              errorMsg += `${
                t('ADMIN_UPLOAD_ZONE_WIDTH_MUST_BE_X', { width: pixelWidth.toString() })
              }\n`;
            }
          }
          if (exactHeight) {
            heightOk = img.height === pixelHeight;
            if (!heightOk) {
              errorMsg += `${
                t('ADMIN_UPLOAD_ZONE_HEIGHT_MUST_BE_X', { height: pixelHeight.toString() })
              }\n`;
            }
          }
          if (minWidth) {
            widthOk = img.width >= pixelWidth;
            if (!widthOk) {
              errorMsg += `${
                t('ADMIN_UPLOAD_ZONE_WIDTH_MUST_BE_AT_LEAST_X', { width: pixelWidth.toString() })
              }\n`;
            }
          }
          if (minHeight) {
            heightOk = img.height >= pixelHeight;
            if (!heightOk) {
              errorMsg += `${
                t('ADMIN_UPLOAD_ZONE_HEIGHT_MUST_BE_AT_LEAST_X', { height: pixelHeight.toString() })
              }\n`;
            }
          }
          if (widthOk && heightOk) {
            resolve();
          } else {
            reject(new InvalidDimensionsError({
              errorKey: errorMsg ? '' : errorKey,
              errorMsg,
            }));
          }
        }
      };
      img.src = url;
    });
  },
);

export const uploadMedia = async (
  files: File[],
  token: string,
  onProgress?: (progress: number) => unknown,
) => {
  const file = files[0];
  const formData = new FormData();
  formData.append('adminFile', file);

  const options: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (!onProgress) { return; }
      const { loaded, total } = progressEvent;
      // send progress percentage to progress bar
      onProgress(Math.round((loaded / total) * 100));
    },
  };

  return axios.post(MEDIA_SERVICE_BASE_URL, formData, options);
};

export default injectT(
  (t) => function getState(props: UploadZoneProps & { primaryToken: string; siteId: string; }) {
    const { onFileSubmit, siteId } = props;
    const constructUrl = (fileData: ImageInfo) => {
      const { ext, _id: mediaId } = fileData;
      const thumbnail = `${MEDIA_ASSETS_BASE_URL}/${siteId}/${mediaId}.${ext}`;
      return thumbnail;
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useReducer(reducer, initialState);

    const actions = {
      handleSelectFromLibrary: (selectedImage: ImageInfo) => {
        const { dimensionRequirements } = props;
        const { ext, _id: mediaId } = selectedImage;
        const url = `${MEDIA_ASSETS_BASE_URL}/${siteId}/${mediaId}.${ext}`;
        areDimensionsValid(t, url, dimensionRequirements)
          .then(() => {
            dispatch({ type: ACTION_TYPES.UPLOAD_SUCCESSFUL });
            onFileSubmit?.(constructUrl(selectedImage));
          })
          .catch((err) => {
            dispatch({
              payload: {
                errorKey: err.errorKey,
                errorMsg: err.errorMsg,
              },
              type: ACTION_TYPES.UPLOAD_REJECTED,
            });
          });
      },
      reject: (files: File[]) => {
        const { maxFiles, maxSize } = props;
        let errorKey = 'UPLOAD_ZONE_ERROR_DEFAULT';
        if (files.length > (maxFiles ?? Infinity)) {
          errorKey = 'UPLOAD_ZONE_ERROR_MAX_FILES';
        } else if (files.some(file => file.size > (maxSize ?? Infinity))) {
          errorKey = 'UPLOAD_ZONE_ERROR_SIZE_LIMIT';
        } else {
          errorKey = 'UPLOAD_ZONE_ERROR_FILE_TYPE';
        }
        dispatch({
          payload: errorKey,
          type: ACTION_TYPES.UPLOAD_REJECTED,
        });
      },
      removeImage: (event: MouseEvent) => {
        const { onClearImage } = props;
        event.stopPropagation();
        onClearImage?.();
      },
      toggleActive: (isActive?: boolean) => {
        dispatch({
          status: isActive,
          type: ACTION_TYPES.TOGGLE_HOVER,
        });
      },
      toggleLibrary: (event: MouseEvent) => {
        if (event) {
          event.stopPropagation();
        }
        dispatch({ type: ACTION_TYPES.TOGGLE_LIBRARY });
      },
      upload: async (files: FileWithPreview[]) => {
        const { primaryToken, dimensionRequirements, onProgress } = props;
        /**
         * Image dimension check has to be at this point as
         * resolution data is not available until files have
         * been "accepted" and we have access to their contents
         */
        const fileDimensionRequirementResults = [];
        if (dimensionRequirements && dimensionRequirements.verify) {
          for (const file of files) {
            const promise = areDimensionsValid(t, file.preview!, dimensionRequirements);
            fileDimensionRequirementResults.push(promise);
          }
        }
        Promise
          .all(fileDimensionRequirementResults)
          .then(async () => {
            dispatch({ type: ACTION_TYPES.UPLOAD_REQUESTED });
            try {
              const response = await uploadMedia(files, primaryToken, onProgress);
              const thumbnail = constructUrl(response.data);
              // NOTE: need to do this in two places because
              // a new image file can come through upload
              // or a picker
              dispatch({ type: ACTION_TYPES.UPLOAD_SUCCESSFUL });
              onFileSubmit?.(thumbnail, response.data);
            } catch (e) {
              dispatch({
                type: ACTION_TYPES.UPLOAD_FAILED,
              });
            }
          })
          .catch((err) => {
            dispatch({
              payload: {
                errorKey: err.errorKey,
                errorMsg: err.errorMsg,
              },
              type: ACTION_TYPES.UPLOAD_REJECTED,
            });
          });
      },
    };

    return [state, actions] as const;
  },
);
