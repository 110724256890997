import React, { useEffect } from 'react';
import styled from 'styled-components';
import IObject from 'models/IObject';
import PageEditorView from 'components/admin-bridge/PageManager/PageEditor';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import PageFetcher from 'components/admin-bridge/Toolbar/PageFetcher';
import useRealtimeDocument from 'hooks/use-realtime-document';
import { removeKeys } from 'services/app-router/actions';
import { getObject } from 'services/app/selectors/common';
import { useDispatch, useSelector } from 'react-redux';
import { TargetTypes } from 'services/admin/constants';
import {
  setPendingPageDoc,
  publishPendingChanges,
  setPendingPageVideoUpdate,
  setPendingAdminChange,
} from 'services/admin/actions';
import {
  hasUnpublishedAdminChanges,
  getDraftHomeId,
  getPendingPageDocs,
  parseAdminQuery,
} from 'services/admin/selectors';
import {
  getCurrentPageId,
} from 'services/app/selectors';
import IGate, { GateKind } from 'models/IGate';
import { removeEmptyFields } from './utils';

export default function PageEditor() {
  const dispatch = useDispatch();
  const homeId = useSelector(getDraftHomeId);
  const pageId = useSelector(getCurrentPageId);
  const pageDraftDocs = useSelector(getPendingPageDocs);
  const query = useSelector(parseAdminQuery);
  const page = useSelector(getObject);
  const [homePage] = useRealtimeDocument<IObject>('objects', homeId);

  const hasCurrentDraft = Boolean(pageDraftDocs[pageId]);
  const canPublish = useSelector(hasUnpublishedAdminChanges) || hasCurrentDraft;

  const onPublish = () => dispatch(publishPendingChanges({ pageId }));

  const onSave = (doc: IObject, channelVideoUpdate?: boolean) => {
    const id = doc?._id;
    if (!id) {
      // TODO: Log error
      return;
    }
    dispatch(setPendingPageDoc(id, doc));

    if (channelVideoUpdate) {
      dispatch(setPendingPageVideoUpdate(id));
    }
  };

  const onSetHomeId = (id: string) => dispatch(setPendingAdminChange(TargetTypes.HOME_ID, id));

  const getInitialDoc = (doc: IObject) => {
    const newObj: IObject = JSON.parse(JSON.stringify(doc));
    if (!doc.data.gate) {
      newObj.data.gate = { kind: GateKind.NONE } as IGate; // TODO: Check if we should send a complete gate object
    }
    return newObj;
  };

  const initialDoc = removeEmptyFields(getInitialDoc(pageDraftDocs[pageId!] || page || homePage));

  useEffect(() => {
    return () => {
      if (query.admin !== 'pages') {
        dispatch(removeKeys(['pageType', 'page']));
      }
    };
  }, [query.admin]);

  return (
      <>
        <PageFetcher />
        <PageEditorView
          canPublish={canPublish}
          docExists={Boolean(page)}
          // Uncomment the following line to reset to first tab when switching pages
          // key={initialDoc._id}
          homeId={homeId || ''}
          /*
            Initial could be fetched by server or firebase.
            Firebase set as undefined empty arrays.
            It could lead to a render issue (infine loop) on
            the page editor at the momento to compare
            server objects with firebase object.
          */
          initialDoc={initialDoc}
          onPublish={onPublish}
          onSave={onSave}
          onSetHomeId={Boolean(page) ? onSetHomeId : null}
        />
        </>
    );
}

const PageSpinner = styled(LoadingSpinner)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
