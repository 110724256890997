import styled, { css } from 'styled-components';
import { Button } from 'components/admin2/ui/Button';
import TextInput from 'components/admin2/TextInput';
import {
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_500,
  ADMIN_ALERT_CONFIRMATION,
  ADMIN_ALERT_ERROR,
} from 'style/constants';
import Icon from 'components/ui/Icon';
import { WithExtraProps } from 'utils';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';

export const Container = styled.div``;

export const StyledTextInput = styled(TextInput as WithExtraProps<typeof TextInput, { marginTop: number }>)`
  ${(props) => props.marginTop ? css`margin-top: ${props.marginTop}px;` : ``}
`;

export const SectionTitle = styled.h4`
  margin: 0;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export const ProviderBox = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: ${ADMIN_SURFACE_5};

  & + & {
    margin-top: 10px;
  }
`;

export const ProviderLogo = styled.img`
  width: 23px;
  height: 23px;
  object-fit: contain;
`;

export const ExpandCollapseProvider = styled.button`
  border: none;
  margin: none;
  padding: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

export const ProviderName = styled.div`
  display: flex;
  align-items: center;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  & img {
    margin-right: 7px;
  }
`;

export const ProviderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ExpandCollapseArrow = styled(
  Icon as WithExtraProps<typeof Icon, { expanded: boolean }>,
).attrs(props => ({
    name: 'cleanDownGradArrow',
    color: ADMIN_ACCENT_PRIMARY(props),
  }))`
  transform: rotateZ(${(props) => props.expanded ? '180deg' : '0'});
  transition: transform 0.3s;
  & > svg {
    width: 12px;
    height: auto;
  }
`;

export const ProviderForm = styled.form`
  margin-top: 10px;
  & input {
    font-size: 14px !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  & button {
    flex: 1;
  }
`;

export const StyledButton = styled(Button)`
  padding: 15px;
`;

export const AccountStatusLabel = styled.span<{ shouldShowForm: boolean, valid: boolean; }>`
  color: ${(props) =>
    createTextContrastOnBackgroundColor(
      props.valid ? ADMIN_ALERT_CONFIRMATION(props) : ADMIN_ALERT_ERROR(props),
      ADMIN_TEXT_100(props),
      ADMIN_TEXT_500(props),
    )};
  background: ${props => props.valid ? ADMIN_ALERT_CONFIRMATION : ADMIN_ALERT_ERROR};
  padding: 1px 9px;
  ${ADMIN_TEXT_LABEL_XS_BOLD}
  display: flex;
  align-items: center;
  height: 21px;
  text-transform: ${props => props.valid ? 'uppercase' : 'capitalize'};
  border-radius: 40px;
  ${({ shouldShowForm }) => shouldShowForm && css`margin-right: 5px;`}
`;