import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'components/admin2/TextInput';
import Label from 'components/admin2/ui/Label';
import { useForm } from 'hooks';
import { createSlugFromString } from 'utils';
import JsonDebug from 'components/dev/JsonDebug';
import BundleItems from './BundleItems';
import BundleTotal from './BundleTotal';
import {
  PaddingWrapper,
  ScrollableContent,
  StyledCmsNavHeader,
  StyledTextArea,
  StyledUploadZone,
  Wrapper,
} from './styles';
import AjaxFetch from 'components/core/AjaxFetch';
import { BUNDLE_SERVICE_SERVICE_BASE_URL } from 'config';
import { showAdminErrorModal } from 'services/modals';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app';
import IBundle, { IBundleItem } from 'models/IBundle';
import NeedHelpSection from '../NeedHelpSection';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';

interface INewBundleModalProps {
  onClose: () => void;
}

export interface IItem {
  currency: string;
  id: string;
  name: string;
  price: number;
  sku?: string;
  type: 'ticket' | 'shopify';
}

const getBundleFromUseFormState = (body: any): Omit<IBundle, 'siteId'> => {
  return {
    items: body.items.value.map((i: IBundleItem) => ({
      id: i.id,
      sku: i.sku,
      type: i.type,
    })),
    name: body.name.value,
    presentmentCurrency: body.presentmentCurrency.value,
    price: body.price.value,
    sku: body.sku.value,
    description: body.description.value,
    image: body.image.value,
  };
};

const NewBundleModal: React.FC<INewBundleModalProps> = ({ onClose }) => {
  const [submittedOnce, setSubmittedOnce] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const dispatch = useDispatch();
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const { t } = useAdminTranslation();

  const handleNameChange = React.useCallback((newName: string) => {
    return {
      name: newName,
      sku: createSlugFromString(newName),
    };
  }, [createSlugFromString]);

  const handleSkuChange = React.useCallback((newSku: string) => {
    return {
      sku: newSku.replace(/[^a-zA-Z0-9-]/g, ''),
      skuEditable: true,
    };
  }, []);

  const handleSkuBlur = React.useCallback((newSku: string) => {
    return {
      sku: createSlugFromString(newSku),
      skuEditable: true,
    };
  }, [createSlugFromString]);

  const { state, handlers, blurs, handleSubmit, handleErrors, isValidForm } = useForm({
    fields: {
      name: {
        error: false,
        errorMessage: 'REQUIRED_FIELD',
        onChange: handleNameChange,
        value: '',
      },
      sku: {
        error: false,
        errorMessage: 'ADMIN_ENTITLEMENT_ERROR_SKU',
        onBlur: handleSkuBlur,
        onChange: handleSkuChange,
        value: '',
      },
      description: {
        value: '',
      },
      image: {
        value: undefined,
        withoutInputValue: true,
      },
      items: {
        value: [],
        withoutInputValue: true,
      },
      presentmentCurrency: {
        value: 'USD',
        withoutInputValue: true,
      },
      price: {
        value: 0,
        withoutInputValue: true,
      },
    },
    onError: (_, errors) => {
      const { message } = errors?.[0]?.response?.data;
      dispatch(showAdminErrorModal(message || t('BUNDLE_FATAL_ERROR_WHEN_CREATING')));
      setIsSaved(false);
    },
    onSubmit: formValues => {
      if (!submittedOnce) {
        setSubmittedOnce(true);
      }
      const sameCurrency = formValues.items.value.every((item: IItem) => item.currency.toLowerCase() === formValues.presentmentCurrency.value.toLowerCase());
      const hasTicket = formValues.items.value.some((item: IItem) => item.type === 'ticket');
      const hasShopifyProduct = formValues.items.value.some((item: IItem) => item.type === 'shopify');
      if (!isValidForm() || !hasShopifyProduct || !hasTicket || !sameCurrency) {
        return;
      }
      setIsSaved(true);
    },
  });

  const headerActions = React.useMemo(() => ([
    {
      labelKey: 'ADMIN_ACTION_SAVE' as TranslationKey,
      noMemoizedClick: true,
      onClick: handleSubmit,
      testId: 'saveBundleButton',
    },
  ]), [handleSubmit]);

  const handleClearImage = React.useCallback(() => {
    handlers.image(undefined);
  }, [handlers]);

  const { products, tickets } = React.useMemo(() => {
    const items = state.items.value as IItem[];

    return {
      products: items.filter(item => item.type === 'shopify'),
      tickets: items.filter(item => item.type === 'ticket'),
    };
  }, [state.items]);

  React.useEffect(() => {
    const items = state.items.value as IItem[];
    // set the presentment currency to the currency of the first item added
    if (items.length > 0) {
      handlers.presentmentCurrency(items[0].currency);
    }

    // Calculate total price
    const total = items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    handlers.price(total);
  }, [state.items]);

  // Description is automatically generated from the items included but can be modified
  // Bulleted list of the names of each Ticket and Product, in that order.
  React.useEffect(() => {
    const items = state.items.value as IItem[];
    const itemTickets = items.filter(item => item.type === 'ticket');
    const itemProducts = items.filter(item => item.type === 'shopify');

    let description = itemTickets.reduce((prev, curr) => {
      if (prev) {
        return `${prev}\n- ${curr.name}`;
      }
      return `- ${curr.name}`;
    }, '');
    description = itemProducts.reduce((prev, curr) => {
      if(prev) {
        return `${prev}\n- ${curr.name}`;
      }
      return `- ${curr.name}`;
    }, description);

    handlers.description(description);
  }, [state.items]);

  const handleRemoveBundleItem = React.useCallback((id: string) => () => {
    handlers.items(state.items.value.filter((item: IItem) => item.id !== id));
  }, [state.items, handlers]);

  const handleAddBundleItem = React.useCallback((item: IItem) => {
    const newItems = [...(state.items.value as IItem[]), item];
    handlers.items(newItems);
  }, [state.items, handlers]);

  const handleAddProductItems = React.useCallback((newItems: IItem[]) => {
    const items = state.items.value as IItem[];
    const filtered = items.filter(item => item.type === 'ticket');
    handlers.items([...filtered, ...newItems]);
  }, [state.items, handlers]);

  const itemsHasSameCurrency = React.useMemo(() => {
    return state.items.value.every((item: IItem) => item.currency.toLowerCase() === state.presentmentCurrency.value.toLowerCase());
  }, [state.items, state.presentmentCurrency]);

  return (
    <Wrapper data-testid="newBundleModal">
      <StyledCmsNavHeader onBack={onClose} actions={headerActions} titleKey="LABEL_CREATE_BUNDLE" />
      <PaddingWrapper>
      <ScrollableContent>
        <StyledUploadZone
          imagePreview={state.image.value}
          onClearImage={handleClearImage}
          onFileSubmit={handlers.image}
          labelKey="BUNDLE_IMAGE"
        />
        <TextInput
          data-testid="newBundleNameInput"
          descriptionKey="ADMIN_ENTITLEMENT_UPDATE_NAME_DESCRIPTION"
          errorKey={state.name.errorMessage}
          labelKey="ADMIN_LABEL_NAME"
          maxlength={25}
          onChange={handlers.name}
          placeholderKey="ADMIN_PLACEHOLDER_ENTER_NAME"
          showDangerErrorMessage={state.name.error}
          type="text"
          value={state.name.value}
        />
        <TextInput
          data-testid="newBundleSkuInput"
          descriptionKey="BUNDLE_SKU_DESCRIPTION"
          labelKey="ADMIN_LABEL_SKU"
          maxlength={25}
          errorKey={state.sku.errorMessage}
          showDangerErrorMessage={state.sku.error}
          onChange={handlers.sku}
          placeholderKey="BUNDLE_SKU_DESCRIPTION"
          type="text"
          value={state.sku.value}
          onBlur={blurs.sku}
        />
        <BundleItems
          tickets={tickets}
          products={products}
          onAdd={handleAddBundleItem}
          onAddProducts={handleAddProductItems}
          onRemove={handleRemoveBundleItem}
        />
        <StyledTextArea onChange={handlers.description} value={state.description.value} />
      </ScrollableContent>
      <Label labelKey="ADMIN_LABEL_PRICING" descriptionKey="BUNDLE_PRICE_DESCRIPTION" />
      <BundleTotal
        itemsHasSameCurrency={itemsHasSameCurrency}
        submittedOnce={submittedOnce}
        hasProduct={Boolean(products.length)}
        hasTicket={Boolean(tickets.length)}
        currency={state.presentmentCurrency.value}
        total={state.price.value}
      />
      <NeedHelpSection />
      </PaddingWrapper>
      <JsonDebug value={state} />
      {isSaved && (
        <AjaxFetch
          body={getBundleFromUseFormState(state)}
          headers={{
            Authorization: `Bearer ${primaryToken}`,
            'x-maestro-client-id': siteId,
          }}
          method="POST"
          onError={handleErrors}
          onValue={onClose}
          url={BUNDLE_SERVICE_SERVICE_BASE_URL}
        />
      )}
    </Wrapper>
  );
};

export default NewBundleModal;
