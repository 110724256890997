import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import AdminModal from 'components/admin2/ui/AdminModal';
import { Button as AdminButton } from 'components/admin2/ui/Button';
import {
  SPACING_SMALL,
  SPACING_LARGE,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_1,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_200,
} from 'style/constants';
import {
  ACTION_NORMAL,
  ACTION_DISABLED,
  ADMIN_ACTION_NORMAL_SMALL,
} from 'style/mixins';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';
// TODO: add back when we fix iframe stuff
// import UploadZone from 'components/ui/UploadZone';

export const AddButton = styled(AdminButton)`
  justify-content: space-between;
  height: 35px;
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'plus-thin',
}))`
  margin-right: 15px;
`;

export const StyledAdminModal = styled(AdminModal)`
  border-radius: 0;
  transition: width 0.5s, height 0.5s;
  width: calc(85% - ${({ adminbarWidth }) => adminbarWidth}px);
  height: 85%;
`;

export const Spinner = styled(LoadingSpinner)`
  && {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-flow: column nowrap;
`;

export const Footer = styled.div`
  content: "";
  background-color: ${ADMIN_SURFACE_4};
  width: 100%;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 301;
`;

export const DownArrow = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'downArrowGrad',
}))`
  line-height: 0;
  position: absolute;
  right: 20px;
  bottom: 22px;
  overflow: hidden;
  & svg {
    margin: -20px;
    width: 59px;
    height: 50px;
  }
`;

export const Header = styled.div`
  padding: 0 29px;
  background-color: ${ADMIN_SURFACE_1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  flex-shrink: 0;
`;

export const UpperFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.div`
  ${ACTION_NORMAL}
  ${props => props.disabled && ACTION_DISABLED}
  &:not(:first-child) {
    margin-left: ${SPACING_LARGE};
  }
`;

export const Title = styled.div`
  ${ADMIN_TEXT_TITLE_S}
  text-transform: uppercase;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 35px,
`;

export const HelperText = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-top: ${SPACING_SMALL};
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  & > div {
    width: 100%;
  }
  margin: 0 20px;
  position: absolute;
  top: 80px;
  background: ${ADMIN_SURFACE_4};
  z-index: 301;
  right: 0;
  left: 0;
`;

export const UploadInput = styled.input.attrs({
  accept: 'image/*',
  type: 'file',
})`
display: none;
`;

// TODO: add back when we fix drag and drop uploading
// export const UploadContainer = styled.div`
//     /*
//     NOTE:
//     !important because w/o a className DropZone styles the component
//     with
//     */
//     flex: 1;
//     display: flex;
//     & .uploadZone{
//       width: 100%;
//       *{
//         border-left: 0;
//         border-bottom: 0;
//         border-right: 0;
//       }
//     }
// `;

export const NoResults = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const SortButton = styled.div`
  ${ADMIN_ACTION_NORMAL_SMALL}
  display: flex;
  flex-direction: row;
  padding: 0 ${SPACING_SMALL};
`;

export const ArrowIcon = styled(Icon).attrs({
  name: 'down-open',
})`
  transition: transform 0.4s ease;
    ${
  props => props.isAscending ? `
          transform: rotateZ(180deg);
        ` :
    'transform: rotateZ(0deg);'
}
`;
