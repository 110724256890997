import IState from 'services/state';
import { Dispatch } from 'redux';
import { hasUnsavedChanges, getConfirmationAction } from 'services/admin/selectors';
import { runConfirmationAction, clearConfirmationAction } from 'services/admin/actions';

export const mapStateToProps = (state: IState) => ({
  confirmationAction: getConfirmationAction(state),
  hasUnsavedChanges: hasUnsavedChanges(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  runConfirmationAction: () => dispatch(runConfirmationAction()),
  clearConfirmationAction: () => dispatch(clearConfirmationAction()),
});

export type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
