import styled, { css } from 'styled-components';
import { rgba } from 'colors';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ALERT_WARNING,
  ADMIN_ALERT_ERROR,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_6,
} from 'style/constants';
import { ACTION_ICON_NORMAL } from 'style/mixins';
import ErrorBox from 'components/admin2/ui/ErrorBox';
import Icon from 'components/ui/Icon';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Label from 'components/admin2/Label';
import IconButton from 'components/admin2/ui/IconButton';
import Dropzone from 'react-dropzone';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_S_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

const ICON_STYLES = css`
  ${ACTION_ICON_NORMAL}
  cursor: pointer;
  &::before{
    width: 20px;
    height: 20px;
    padding: 6px;
    margin: 0;
  }
`;

export const ErrorMsg = styled(ErrorBox).attrs({
  borderless: true,
  compact: true,
  uppercase: true,
})`
  position: absolute;
  right: 0px;
  top: -24px;
`;

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex: 1;
  height: 100%;
  position: relative;
  box-sizing: content-box;
  background: ${ADMIN_SURFACE_5};
  color: ${ADMIN_TEXT_100};
`;

export const ImageLibraryButton = styled(Icon).attrs(
  props => ({
    color: ADMIN_TEXT_100(props),
    name: 'uploadPicture',
  }),
)`
  margin-top: -2px;
  ${ICON_STYLES}
`;

export const UploadText = styled.div`
  margin-left: 8px;
`;

export const TextContainer = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

export const BigTitle = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export const SmallTitle = styled.span`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  color: ${ADMIN_TEXT_300};
  margin: 2.5px 0;
`;

export const Image = styled.img`
  display: inline-block;
  object-fit: contain;
  flex: 1;
`;

export const ImageContainer = styled.div<{src?: string | null}>`
  align-items: center;
  display: flex;
  flex: 1;
  border-radius: 4px;
  justify-content: center;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
`;

export const ErrorText = styled.span`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${ADMIN_ALERT_ERROR};
  bottom: 5px;
  white-space: pre-wrap;
`;

export const ActionContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const StyledLabel = styled(Label)``;

export const WarningLabel = styled(Label)`
  color: ${ADMIN_ALERT_WARNING};
  margin-left: 10px;
`;

export const ProgressIndicator = styled(LoadingSpinner)`
  /* TODO: replace with custom Admin progress spinner */
  & * {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const ButtonContainer = styled.div<{ libraryDisabled?: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  & > * {
    flex: 0 0 50%;
    &:first-child {
      flex: 0 0 ${({ libraryDisabled }) => libraryDisabled ? '100%' : '47%'};
    }
  }
`;

export const UploadZoneWrapper = styled.div`
  & > *:last-child {
    height: min-content;
    padding-top: 10px;
  }
`;

export const DragMessage = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  transition: opacity 0.5s;
  position: absolute;
  display: flex;
  opacity: 0;
  bottom: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${props => rgba(ADMIN_SURFACE_1(props), 0.5)};
  &, & ${SmallTitle} {
    color: ${ADMIN_TEXT_100};
  }
  border-radius: 4px;
  pointer-events: none;
`;

export const UploadButton = styled(IconButton).attrs(props => ({
  background: ADMIN_SURFACE_5(props),
  hoverBackground: ADMIN_SURFACE_6(props),
  color: ADMIN_TEXT_100(props),
}))`
  height: 38px;
  margin-top: 6px;
  &:hover ~ ${DragMessage} {
    opacity: 1;
  }
`;

export const StyledDropzone = styled(Dropzone)`
  background: ${ADMIN_SURFACE_5};
  border-radius: 4px;
  transition: margin-top 0.5s;
  ${({ height = 120 }) => css`
    &, & + ${ButtonContainer} ${DragMessage} {
      height: ${height}px;
    }
  `}
  &:hover {
    & + ${ButtonContainer} ${DragMessage} {
      opacity: 1;
    }
  }
  & input {
    width: 100%;
  }
`;
